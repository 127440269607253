.form-div-desktop {
  width: 350px;
  top: 12%;
  right: 7%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  backdrop-filter: blur(10px);
}

.form-div-mobile {
  width: 400px;
  background: linear-gradient(
    to bottom,
    #004dac 0%,
    rgba(12, 54, 126, 0.8) 100%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

label {
  font-family: Metropolis-Medium !important;
}

.form-mobile-div {
  background-color: #f5f5f5;
  border-radius: 15px;
}

.submit-btn {
  background-color: #004dac;
  padding: 0.6em;
  color: white;
}

.submit-btn:hover {
  background-color: black;
  color: white;
}

.form-div-mobile {
  display: none;
}

.cta {
  top: 53%;
  left: 6.5%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .submit-btn {
    background-color: white;
    color: #004dac;
  }
  .form-div-desktop {
    display: none;
  }
  .form-div-mobile {
    display: block;
  }
}

@media screen and (max-width: 650px) {
  .form-div-desktop {
    top: 44%;
    transform: translateX(-50%);
    left: 50%;
  }
  .form-div-mobile {
    display: none;
  }
  .cta {
    top: 24%;
    left: 50%;
    transform: translateX(-50%);
  }
}
